var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"mt-4",attrs:{"headers":_vm.headers,"items":_vm.items},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" "),(item.id in _vm.conflicts)?_c('v-icon',{staticClass:"ml-2",attrs:{"color":"error"}},[_vm._v("mdi-calendar-alert")]):_vm._e()]}},{key:"item.start",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatLocalizedDate(item.start)))]}},{key:"item.startTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatLocalizedTime(item.start)))]}},{key:"item.endTime",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.formatLocalizedTime(item.end)))]}},(!_vm.isCourseDone)?{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.handleClickEdit({ calendarEvent: item, nativeEvent: $event })}}},[_vm._v("mdi-pencil")])]}}:null],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }