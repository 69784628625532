
















import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { CourseDto } from "@/store/modules/courses/interfaces/Course.interface";
import { defineComponent, PropType } from "@vue/composition-api";
import { PlanState } from "../../new/steps/plan/schedule.types";
import ScheduleForm from "../../new/steps/plan/ScheduleForm.vue";

export default defineComponent({
  name: "CourseCalendar",
  props: {
    course: {
      type: Object as PropType<CourseDto>,
      required: true,
    },
    hoursMax: {
      type: Number,
      required: false,
    },
    isCourseDone: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["updateCalendar"],
  components: { ScheduleForm },
  setup(props, { emit }) {
    const handleUpdatePlan = async ({ plan, validation }: PlanState) => {
      globalLoadingWrapper({ blocking: true }, async () => {
        emit("updateCalendar", plan, validation);
      });
    };

    return {
      handleUpdatePlan,
    };
  },
});
