

























import { ApiGetCourseDto, ApiGetCourseParticipantDto } from "@/api/generated/Api";
import { useRoute } from "@/shared/useHelpers";
import { defineComponent, PropType, ref } from "@vue/composition-api";
import { CourseStatus } from "@/shared/enums/CourseStatus.enum";

export default defineComponent({
  name: "IconCards",
  props: {
    courseParticipantStudents: {
      type: Array as PropType<ApiGetCourseParticipantDto[]>,
      required: true,
    },
    course: {
      type: Object as PropType<ApiGetCourseDto>,
      required: true,
    },
    isVocationalSchool: {
      type: Boolean,
      required: true,
    },
    numberOfNewMessages: {
      type: Number,
      required: true,
    },
    numberOfOpenCourseOrders: {
      type: Number,
      required: true,
    },
    numberOfNotes: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const route = useRoute();
    const courseId = +route.params.id;
    const iconCardsList = ref([
      {
        headline: "Antall nye meldinger",
        icon: "mdi-email",
        link: `/kurset/${courseId}/messages?tab=unread`,
        value: props.numberOfNewMessages.toString(),
      },
      {
        headline: "Antall åpne ordre",
        icon: "mdi-cash-register",
        link: `/kurset/${courseId}/economy`,
        value: props.numberOfOpenCourseOrders.toString(),
      },
      {
        headline: "Antall notater",
        icon: "mdi-file-document",
        link: `/kurset/${courseId}/notes`,
        value: props.numberOfNotes.toString(),
      },
      {
        headline: "Antall påmeldte",
        icon: "mdi-account-group",
        link: `/kurset/${courseId}/participants?status=${CourseStatus.SignedUp}`,
        value: filterItemsByStatus(props.courseParticipantStudents, CourseStatus.SignedUp).length.toString(),
      },
      {
        headline: "Antall på venteliste",
        icon: "mdi-account-convert",
        link: `/kurset/${courseId}/participants?status=${CourseStatus.WaitingList}`,
        value: filterItemsByStatus(props.courseParticipantStudents, CourseStatus.WaitingList).length.toString(),
      },
      {
        headline: "Antall søknader",
        icon: "mdi-account-clock",
        link: `/kurset/${courseId}/participants?status=${CourseStatus.Applied}`,
        value: filterItemsByStatus(props.courseParticipantStudents, CourseStatus.Applied).length.toString(),
      },
      {
        headline: props.isVocationalSchool ? "Antall kvalifiserte" : "Antall godkjente",
        icon: "mdi-account-multiple-check",
        link: `/kurset/${courseId}/participants?status=${CourseStatus.Qualified}`,
        value: filterItemsByStatus(props.courseParticipantStudents, CourseStatus.Qualified).length.toString(),
      },
      {
        headline: "Antall interesserte",
        icon: "mdi-account-alert",
        link: `/kurset/${courseId}/participants?status=${CourseStatus.Interested}`,
        value: filterItemsByStatus(props.courseParticipantStudents, CourseStatus.Interested).length.toString(),
      },
      {
        headline: "Antall avmeldte/avslåtte",
        icon: "mdi-account-cancel",
        link: `/kurset/${courseId}/participants?status=${CourseStatus.Denied}`,
        value: filterItemsByStatus(
          props.courseParticipantStudents,
          CourseStatus.Denied,
          CourseStatus.Unsubscribed
        ).length.toString(),
      },
      {
        headline: "Antall fullførte",
        icon: "mdi-school",
        link: `/kurset/${courseId}/participants?status=${CourseStatus.Finished}`,
        value: filterItemsByStatus(props.courseParticipantStudents, CourseStatus.Finished).length.toString(),
      },
    ]);

    return { iconCardsList };
  },
});
const filterItemsByStatus = (inputArray: ApiGetCourseParticipantDto[], query: string, secondQuery?: string) =>
  inputArray.filter((item) => item.status === query || item.status === secondQuery);
