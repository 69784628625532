













































import { ApiGetCourseScheduleDtoType } from "@/api/generated/Api";
import { getRoundedHours } from "@/shared/helpers/scheduleHelpers";
import { computed, defineComponent, PropType } from "@vue/composition-api";
import { differenceInMinutes } from "date-fns";

export default defineComponent({
  name: "ScheduleBanners",
  props: {
    schedules: {
      type: Array as PropType<ApiGetCourseScheduleDtoType[]>,
      required: true,
    },
    hoursMax: {
      type: Number,
      required: true,
    },
    hoursWithInstructor: {
      type: Number,
      required: true,
    },
    validationState: {
      type: Object as PropType<Record<string, boolean>>,
      required: true,
    },
  },
  setup(props) {
    const scheduleHours = computed(() => {
      const totalScheduledHours = props.schedules
        .map(({ start, end }) => ({ start, end }))
        .reduce((previousValue, currentValue) => {
          const amountOfMinutes = differenceInMinutes(new Date(currentValue.end), new Date(currentValue.start));
          return previousValue + amountOfMinutes;
        }, 0);
      return +(totalScheduledHours / 60).toFixed(1);
    });

    return { scheduleHours, getRoundedHours };
  },
});
