



















































































































import { api } from "@/api/api";
import {
  ApiGetCourseDto,
  ApiGetCourseParticipantDto,
  ApiGetCurriculumDto,
  ApiGetExpenseDto,
  ApiGetMessageResultDto,
  ApiGetPlanDtoType,
  ApiGetWorkingHourDto,
} from "@/api/generated/Api";
import CourseCalendar from "@/components/course/details/calendar/CourseCalendar.vue";
import CourseCard from "@/components/course/details/CourseCard.vue";
import CourseDashboardBanner from "@/components/course/details/CourseDashboardBanner.vue";
import IconCards from "@/components/course/details/IconCards.vue";
import ModifyClosedCourseModal from "@/components/course/details/ModifyClosedCourseModal.vue";
import BaseLayout from "@/components/shared/BaseLayout.vue";
import BaseModal from "@/components/shared/BaseModal.vue";
import { getInitialModalData, useOpenModal } from "@/fragments/modal/useOpenModal";
import { CourseOrderStatus } from "@/shared/enums/courseOrderStatusEnum";
import { CourseParticipantExpenseStatus } from "@/shared/enums/CourseParticipantExpenseStatus.enum";
import { CourseStatus } from "@/shared/enums/CourseStatus.enum";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { CourseRouteNames } from "@/shared/enums/RouteNames/courseRouteNames.enum";
import { SingleCourseRouteNames } from "@/shared/enums/RouteNames/singleCourseRouteNames.enum";
import { WorkingHourStatusEnum } from "@/shared/enums/WorkingHourStatus.enum";
import { hasMemberOrgAccess } from "@/shared/helpers/accessLevelApiAdapter";
import { hasModifyClosedCourseAccess, loadAllCourseData } from "@/shared/helpers/courseHelpers";
import { isVocationalSchool } from "@/shared/helpers/curriculumHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { useNavigateBack } from "@/shared/helpers/navigationHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { capitalize } from "@/shared/helpers/textHelpers";
import { useRoute, useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, onMounted, ref } from "@vue/composition-api";
import axios from "axios";
import { isPast } from "date-fns";
import { loadMessages } from "./CourseMessagesPage.vue";

export default defineComponent({
  name: "CourseDetailsPage", // TODO rename to dashboard?
  components: {
    CourseCalendar,
    IconCards,
    CourseDashboardBanner,
    BaseLayout,
    CourseCard,
    BaseModal,
    ModifyClosedCourseModal,
  },
  setup() {
    const store = useStore<StoreState>();
    const route = useRoute();
    const courseId = +route.params.id;
    const course = ref<ApiGetCourseDto>();
    const courseParticipantStudents = ref<ApiGetCourseParticipantDto[]>([]);
    const curriculum = ref<ApiGetCurriculumDto>();
    const contentReady = ref(false);
    const isNotificationOpen = ref(false);
    const workingHoursPendingApproval = ref<ApiGetWorkingHourDto[]>();
    const courseParticipantExpensesPendingApproval = ref<ApiGetExpenseDto[]>();
    const isCalendarValid = ref(true);
    const validationState = ref<Record<string, boolean>>();
    const newMessages = ref<ApiGetMessageResultDto[]>([]);
    const numberOfOpenCourseOrders = ref(0);
    const numberOfNotes = ref(0);
    const courseDashboardTitle = ref("");
    const modalData = ref(getInitialModalData());

    const isEndCourseAvailable = computed(
      () => course.value && course.value.status === CourseStatus.Open && isPast(new Date(course.value.endDate))
    );

    const isModifyClosedCourseAvailable = computed(
      () => course.value && course.value.status === CourseStatus.Closed && hasModifyClosedCourseAccess
    );

    const isCourseDone = computed(() => course?.value?.status === CourseStatus.Closed);

    onMounted(async () =>
      Promise.all([
        loadCourse(),
        loadWorkingHoursForApproval(),
        loadCourseParticipantExpensesForApproval(),
        loadMessages(courseId, newMessages, false, false),
        loadOpenCourseOrders(),
        loadCourseNotes(),
      ])
    );

    const notificationCount = computed(
      () =>
        (workingHoursPendingApproval.value?.length ?? 0) + (courseParticipantExpensesPendingApproval.value?.length ?? 0)
    );

    const loadCourse = () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        ({
          course: course.value,
          courseParticipantStudents: courseParticipantStudents.value,
          studyplan: curriculum.value,
        } = await loadAllCourseData(courseId));
        courseDashboardTitle.value = getCourseDashboardTitle();
        contentReady.value = true;
      });
    };

    const getCourseDashboardTitle = () =>
      `${courseId} - ${course.value?.courseName} ${course.value?.kasId ? `(KAS-ID: ${course.value?.kasId})` : ""}`;

    const loadWorkingHoursForApproval = async () => {
      if (hasMemberOrgAccess) {
        return;
      }
      globalLoadingWrapper({ blocking: true }, async () => {
        workingHoursPendingApproval.value = (
          await api.economy.getWorkingHoursAsync({ CourseId: courseId, Statuses: [WorkingHourStatusEnum.NotApproved] })
        ).data;
      });
    };

    const loadCourseParticipantExpensesForApproval = async () => {
      if (hasMemberOrgAccess) {
        return;
      }
      globalLoadingWrapper({ blocking: true }, async () => {
        const expenses = (
          await api.courseparticipantexpense.getCourseParticipantExpenseAsync({ CourseId: +route.params.id })
        ).data;
        courseParticipantExpensesPendingApproval.value = expenses.filter(
          (expense) => expense.status === CourseParticipantExpenseStatus.Draft
        );
      });
    };

    const loadOpenCourseOrders = async () => {
      if (hasMemberOrgAccess) {
        return;
      }
      numberOfOpenCourseOrders.value = (await api.course.getOrdersAsync(courseId)).data.filter(
        (order) => order.status?.id === CourseOrderStatus.DraftValue
      ).length;
    };

    const loadCourseNotes = async () => {
      if (hasMemberOrgAccess) {
        return;
      }
      numberOfNotes.value = (await api.course.getCourseNotesAsync(courseId)).data.length;
    };

    const editCourse = () => ({
      name: CourseRouteNames.EditCourse,
      params: {
        editCourseId: route.params.id,
      },
    });

    const endCourse = () => ({
      name: SingleCourseRouteNames.EndCourse,
      params: {
        courseId: route.params.id,
      },
    });

    const reopenCourse = useOpenModal(ModalType.Unspecified, "Reåpne kurs", modalData);

    const navigateToWorkingHours = () => ({
      name: SingleCourseRouteNames.CourseWorkingHours,
      params: {
        id: route.params.id,
      },
    });

    const navigateToCourseParticipantExpenses = () => ({
      name: SingleCourseRouteNames.CourseExpenses,
      params: {
        id: route.params.id,
      },
    });

    const navigateToCourseList = () => useNavigateBack(CourseRouteNames.CourseList);

    const updateCalendar = (plan: ApiGetPlanDtoType, validation: Record<string, boolean>) => {
      if (!course.value?.plan) {
        return;
      }
      course.value.plan = plan;
      validationState.value = validation;
      isCalendarValid.value = Object.values(validation).every((valid) => valid);
    };

    const updateSchedule = async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        if (!course.value?.plan) {
          return;
        }
        try {
          await api.course.batchUpdateSchedule(+route.params.id, course.value.plan);
          openNotification(store, NotificationItemType.Success, "Timeplan oppdatert");
        } catch (err: unknown) {
          if (!axios.isAxiosError(err) || !err.response) {
            return;
          }
          err.response.data.generalValidationErrors.map((errorMsg: string) =>
            openNotification(store, NotificationItemType.Warning, capitalize(errorMsg))
          );
          course.value = (await api.course.getCourseByIdFilteredByDepartmentAsync(+route.params.id)).data;
        }
      });
    };

    return {
      contentReady,
      notificationCount,
      workingHoursPendingApproval,
      courseParticipantExpensesPendingApproval,
      isNotificationOpen,
      course,
      curriculum,
      isCalendarValid,
      courseParticipantStudents,
      validationState,
      updateCalendar,
      updateSchedule,
      editCourse,
      endCourse,
      navigateToWorkingHours,
      hasMemberOrgAccess,
      navigateToCourseParticipantExpenses,
      isEndCourseAvailable,
      isVocationalSchool: computed(() => isVocationalSchool(curriculum.value?.mainCourseId)),
      isCourseDone,
      newMessages,
      numberOfOpenCourseOrders,
      numberOfNotes,
      navigateToCourseList,
      courseDashboardTitle,
      isModifyClosedCourseAvailable,
      reopenCourse,
      modalData,
    };
  },
});
